import styled from 'styled-components';

export const Svg = styled.div`
  width: 72px;
  position: fixed;
  right: 30px;
  bottom: 30px;
  background-color: #02A884;
  color: #fff;
  padding: 15px;
  border-radius: 100px;
`;


