import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container } from './styles';
import Footer from '~/components/Footer';
import LocalCatalogo from '~/components/LocalCatalogo';
import { imgs } from '~/assets/banners-lado-lado';
import logo from '~/assets/logo_header_neg.png';

function Produtos() {
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Anaconda - Produtos</title>
        </Helmet>
        <div className="row-100 fundo-banner">
          <div className="container h-100 d-flex align-items-center justify-content-end ">
            <div>
              <h1 className=" ml-auto mr-2 w-fit-content">
                NOSSA LINHA DE PRODUTOS
              </h1>
              <img src={logo} alt="Logo Anaconda" />
            </div>
          </div>
        </div>
        <LocalCatalogo />
        <div className="container py-4">
          <div className="row mobile-column p-0">
            <div className="col d-flex p-0">
              <div className="col d-flex align-items-center p-2">
                <div>
                  <h4 className="todos font-weight-bold">
                    TODOS OS <br />
                    PRODUTOS
                  </h4>
                  <p className="saiba-mais">
                    Saiba mais sobre
                    <br /> nossas linhas
                  </p>
                </div>
              </div>
              <Link
                to={`${process.env.PUBLIC_URL}/biondina`}
                className="col p-2"
              >
                <img src={imgs.biondina} alt="Biondina" />
              </Link>
            </div>
            <div className="col d-flex  p-0">
              <Link
                to={`${process.env.PUBLIC_URL}/produtos-para-cabelo`}
                className="col p-2"
              >
                <img src={imgs.profissional} alt="Linha profissional" />
              </Link>
              <Link to={`${process.env.PUBLIC_URL}/retok`} className="col  p-2">
                <img src={imgs.retok} alt="Retok" />
              </Link>
            </div>
          </div>
          <div className="row mobile-column p-0">
            <div className="col d-flex p-0">
              <Link
                to={`${process.env.PUBLIC_URL}/dermoglam`}
                className="col p-2"
              >
                <img src={imgs.dermoglam} alt="Dermoglam" />
              </Link>
              <Link
                to={`${process.env.PUBLIC_URL}/maquiagem`}
                className="col p-2"
              >
                <img src={imgs.maquiagem} alt="Maquiagem" />
              </Link>
            </div>
            <div className="col d-flex p-0">
              <Link
                to={`${process.env.PUBLIC_URL}/retok-legs-e-corporal`}
                className="col p-2"
              >
                <img src={imgs.retoklegs} alt="Retok Legs & e Corporal" />
              </Link>
              <Link
                to={`${process.env.PUBLIC_URL}/clareador-facial`}
                className="col p-2"
              >
                <img
                  src={imgs.clareador}
                  alt="Limpeza e Clareador para sua pele"
                />
              </Link>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default Produtos;
