import React from 'react';

function BotaoComprar() {
return(
    <div className="centralizar">
    <a href="https://www.amazon.com.br/s?rh=n%3A16194414011%2Cp_4%3AAnaconda&ref=bl_dp_s_web_16194414011"
    className="btn2 position-relative" target='_blank'>
    COMPRAR ONLINE
  </a>
  </div>
);
}

export default BotaoComprar;