import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;600;700;800;900&family=Open+Sans&display=swap');
  * {
    font-family: 'Montserrat';
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: 0;
  }
  html, body, #root {
    height: 100%;
    color:#1D1D1B;
    background-color: #f5f5f5;
  }
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .dNone{
    display:none;
  }
  body {
    font: 16px 'Montserrat';
  }
  img {
    max-width: 100%;
  }
  a {
    text-decoration: none;
    color:inherit;
  }
  ul {
    list-style: none;
  }
  button {
    cursor: pointer;
    border: none;
    background: transparent;
  }
  h1, h2, h3, h4, h5, h6, input, button{
    font-family: 'Montserrat', sans-serif;
  }
  hr{
    border: none;
    border-top: 1px solid #999;
  }
  .mt {
    margin-top: 20px;
  }
  .text-center{
    text-align: center;
  }
  .mobile-only{
    display: none;
  }
  .border-2 {
    border: 2px solid #6c757d !important;
  }

  .whatsappFloatingLogo{
    
  }
  .whatsappFloatingLogo:hover{
    color:#fff
  }
  #ricm_whatsapp_float_lead_whatsapp_icon:hover{
    animation-name:elementor-animation-pulse;
    animation-duration:1s;
    animation-timing-function:linear;
    animation-iteration-count:infinite
  }
  .whatsappFloatingBtn{
    font-size:14px;
    margin-top:10px;
    width:70%;
    margin-left:15%;
    margin-bottom:10px;
    background-color:#24d366;
    color:#fff;
    padding:10px;
    border-radius:10px
  }
  .ricm_whatsapp_float_lead_privacy_read{
    margin-right:5px
  }
  .ricmWhatsappFloatingLeadConteudo{
    position:fixed;
    bottom:0;
    right:0;
    z-index:1001;
    width:100%;
    background-color:#fff;
    -webkit-border-radius:20px 0 20px 0;
    border-radius:20px 20px 0 0;
    -webkit-box-shadow:3px 3px 5px 2px #d1d1d1;
    box-shadow:3px 3px 5px 2px #d1d1d1;
    padding:5px
  }
  .btn2 {
    border-radius: 0;
    border: 2px solid #000;
    font-weight: bold;
    padding: 0.5rem 1.2rem;
   
    font-size: 0.8rem;
    word-wrap: nowrap;
    color:#000!important;
    margin: 0 auto;
  }

  .btn3 {
    border-radius: 0;
    border: 2px solid #fff;
    font-weight: bold;
    padding: 0.5rem 1.2rem;
   
    font-size: 0.8rem;
    word-wrap: nowrap;
    color:#fff!important;
    margin: 0 auto;
  }
 
  .centralizar{
    margin: 0  auto;
    width: 100%;
    text-align: center;
  }

  p2{
    font-family:Arial,Helvetica,sans-serif;
    font-size:14px;
    color:#757575
  }
  .whatsappFloatingInput{
    margin:0 auto!important;
    border:solid 1.5px #656563;
    border-radius:10px!important;
    margin-bottom:10px!important;
    padding:5px!important
  }
  .visuallyHidden{
    display:none
  }
  .itimes{
    color:#757575;
    position:absolute;
    top:0;
    right:0;
    z-index:2;
    padding:1.25rem 1rem
  }
  .privacidadeWhat{
    font-family:Arial,Helvetica,sans-serif;
    font-size:.8em;
    line-height:1.4em!important
  }
  @media(min-width:360px){
    .ricmWhatsappFloatingLeadConteudo{
        right:45px;
        bottom:45px;
        width:300px;
        -webkit-border-radius:20px 0 20px 20px;
        border-radius:20px 20px 0 20px
    }
  }
  @media(min-width:576px){
    .whatsappFloatingLogo{
        right:15px;
        bottom:40px
    }
    .ricmWhatsappFloatingLeadConteudo{
        right:45px;
        bottom:70px
    }
  }
  @media(min-width:768px){
    .whatsappFloatingLogo,.ricmWhatsappFloatingLeadConteudo{
        right:55px;
        bottom:40px
    }
    .ricmWhatsappFloatingLeadConteudo{
        right:85px;
        bottom:70px
    }
  }
  
`;
