import React from 'react';

import { Container } from './styles';
import logo from '~/assets/logo_header_neg.png';
import PDF from '~/pages/Catalogo/catalogo.pdf';

function BannerCatalogo() {
  return (
    <>
      <Container className="container p-3 pt-5 pb-4">
        <div className="row justify-content-end mr-4 mb-5">
          <div className="col-50 mobile-row mr-5">
            <h3 className="m-0 text-right">CATÁLOGO VIRTUAL</h3>
            <p className="text-center conheca desk-only">
              Conheça todos os produtos
            </p>
          </div>
        </div>
        <div className="row mb-3 justify-content-end mr-5">
          <div className="col-50 d-flex mr-4 mobile-only">
            <div className="col d-flex  mobile-logo mb-2">
              <img src={logo} alt="Logo Anaconda" className="mt-auto" />
            </div>
            <div className="col  eu-quero mr-5">
              <p className="d-flex my-auto baixar align-items-center mx-3 desk-only">
                Clique para baixar
              </p>
              <a href={PDF} type="submit" className="btn" download>
                EU QUERO!
              </a>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default BannerCatalogo;
