/* eslint-disable prettier/prettier */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from '~/pages/Home/index';
import Sobre from '~/pages/Sobre/index';
import Produtos from '~/pages/Produtos/index';
import Localizacao from '~/pages/Localizacao/index';
import Contato from '~/pages/Contato/index';
import Biondina from '~/pages/Biondina/index';
import Profissional from '~/pages/Profissional/index';
import Retok from '~/pages/Retok/index';
import Dermoglam from '~/pages/Dermoglam/index';
import RetokLegs from '~/pages/RetokLegs/index';
import Maquiagem from '~/pages/Maquiagem/index';
import Skin from '~/pages/Skin/index';
import PoliticaPrivacidade from '~/pages/PoliticaPrivacidade/index';

// import Page404 from '~/pages/Page404/';

export default function Routes() {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={Home} />
      <Route path={`${process.env.PUBLIC_URL}/empresa-de-cosmeticos`} exact component={Sobre} />
      <Route path={`${process.env.PUBLIC_URL}/produtos`} exact component={Produtos} />
      <Route path={`${process.env.PUBLIC_URL}/biondina`} exact component={Biondina} />
      <Route path={`${process.env.PUBLIC_URL}/produtos-para-cabelo`} exact component={Profissional} />
      <Route path={`${process.env.PUBLIC_URL}/retok`} exact component={Retok} />
      <Route path={`${process.env.PUBLIC_URL}/dermoglam`} exact component={Dermoglam} />
      <Route path={`${process.env.PUBLIC_URL}/maquiagem`} exact component={Maquiagem} />
      <Route path={`${process.env.PUBLIC_URL}/retok-legs-e-corporal`} exact component={RetokLegs} />
      <Route path={`${process.env.PUBLIC_URL}/clareador-facial`} exact component={Skin} />
      <Route path={`${process.env.PUBLIC_URL}/onde-encontrar`} exact component={Localizacao} />
      <Route path={`${process.env.PUBLIC_URL}/contato`} exact component={Contato} />
      <Route path={`${process.env.PUBLIC_URL}/politicas-privacidade`} exact component={PoliticaPrivacidade} />

      {/* <Route path={`${process.env.PUBLIC_URL}/`} component={Page404} /> */}
    </Switch>
  );
}
