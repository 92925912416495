import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from './styles';
import Footer from '~/components/Footer';
import LocalCatalogo from '~/components/LocalCatalogo';
import BannerCatalogo from '~/components/BannerCatalogo';
import { imgs } from '~/assets/profissional';
import setaPrev from '~/assets/icons/seta-prev.gif';
import setaNext from '~/assets/icons/seta-next.gif';

function Profissional() {
  let pos = 0;

  function next() {
    if (pos + 1 < Array.from(document.querySelectorAll('.info-prod')).length) {
      const width = document.querySelector('.infos').offsetWidth;
      document.querySelector('.infos').scrollLeft += width;
      pos += 1;
      document.querySelector('.seta-prev').style.visibility = 'visible';
      if (
        pos ===
        Array.from(document.querySelectorAll('.info-prod')).length - 1
      ) {
        document.querySelector('.seta-next').style.visibility = 'hidden';
      } else {
        document.querySelector('.seta-prev').style.visibility = 'visible';
      }
    }
  }

  function prev() {
    if (pos - 1 >= 0) {
      const width = document.querySelector('.infos').offsetWidth;
      document.querySelector('.infos').scrollLeft -= width;
      pos -= 1;
      document.querySelector('.seta-next').style.visibility = 'visible';
      if (pos === 0) {
        document.querySelector('.seta-prev').style.visibility = 'hidden';
      } else {
        document.querySelector('.seta-prev').style.visibility = 'visible';
      }
    }
  }
  return (
    <>
      <Container>
        <Helmet>
          <title>
            Anaconda Cosméticos - Linha Profissional de produtos para cabelo
          </title>
          <meta
            name="description"
            content="Conheça a nossa linha profissional de produtos para cabelo Espuma Nylon e neutralizante da Anaconda Cosméticos."
          />
        </Helmet>
        <div className="row-100 fundo-destaque">
          <div className="container h-100 d-flex">
            <div className="ml-5 mobile-ml-1 my-auto">
              <h1 className="text-uppercase linha-profissional">
                Uma linha profissional de produtos para cabelos
              </h1>
              <p className="desc-produto ">
                Conheça a nossa linha profissional de produtos para cabelo
                Espuma Nylon e neutralizante da Anaconda Cosméticos.
              </p>
            </div>
          </div>
        </div>
        <div className="row-100 ">
          <div className="container border bg-white mt-n5 px-4 py-4">
            <h2 className="font-weight-bold text-center py-5 m-auto frase-principal">
              Uma linha profissional de produtos para cabelos
            </h2>
          </div>
        </div>
        <div className="row-100 fundo-tarja">
          <div className="container d-flex h-100">
            <h2 className="text-uppercase text-center m-auto frase-tarja">
              Espuma Nylon: Ideal para o alisamento, ondulação e relaxamento de
              cabelo cacheados
            </h2>
          </div>
        </div>

        <div className="row-100 bg-white border-left border-right border-bottom py-5 mobile-row">
          <div className="container d-flex">
            <button type="button" className="seta-prev my-auto " onClick={prev}>
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>

            <div className="row align-items-center infos">
              <div className="info-prod row px-5">
                <div className="col">
                  <h3 className="font-weight-bold pt-5 pb-4">
                    Linha profissional desenvolvida com matéria-prima alemã
                  </h3>
                  <p>
                    A linha Espuma Nylon da Anaconda Cosméticos é recomendada
                    para o uso profissional e foi desenvolvida com matéria-prima
                    alemã. Os produtos possuem uma textura cremosa para fácil
                    aplicação e diminuem os efeitos do ressecamento após o
                    procedimento nos cabelos.
                  </p>
                  <p>
                    Uma linha ideal para realizar procedimentos químicos que
                    precisam alterar a estrutura do fio para um bom resultado. A
                    Espuma Nylon possui o princípio ativo, Tioglicolato de
                    Amônia que realiza permanente frio, e é recomendada para
                    procedimentos como: escova definitiva, progressiva,
                    relaxamento, permanente, permanente afro e suporte de raiz.
                  </p>
                  <p>
                    Além da Espuma Nylon, a linha contém um produto
                    neutralizante que deve ser usado em conjunto para obter um
                    melhor resultado.
                  </p>
                </div>
                <div className="col d-flex ">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.espumaNylon}
                    alt="Linha profissional EspumaNylon Permanente Fria e relaxamento"
                  />
                </div>
              </div>
              <div className="info-prod row px-5 ">
                <div className="col">
                  <h3 className="font-weight-bold pt-5 pb-4">
                    Cuidados para manter o resultado em casa
                  </h3>
                  <p>
                    Para manter o resultado de salão em casa, a Anaconda
                    Cosméticos desenvolveu uma linha de shampoo e condicionador
                    com pH balanceado para cabelos quimicamente tratados. Além
                    desses produtos, tem um creme reconstrutor feito com óleo de
                    abacate, ideal para nutrir os fios, diminuindo o
                    ressecamento e deixando o cabelo mais macio e maleável.
                  </p>
                </div>
                <div className="col d-flex ">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.materiaPrima}
                    alt="Linha profissional EspumaNylon Shampoo, Condicionador e Creme Reconstrutor"
                  />
                </div>
              </div>
              <div className="info-prod row px-5">
                <div className="col">
                  <h3 className="h4 font-weight-bold pt-5 pb-4">
                    Veja como usar:
                    <br /> Para o procedimento do permanente:
                  </h3>
                  <p>
                    Lave os cabelos com shampoo e seque-o totalmente. Em seguida
                    divida em 4 partes e aplique o produto, mecha por mecha,
                    enrolando com papéis de seda e bigodins, respeitando a
                    distância de 1 cm da raiz.
                  </p>
                  <p>
                    Após a aplicação em todo o cabelo, deixe o produto agir por
                    no máximo 40 minutos em fios grossos e no máximo 20 minutos
                    em fios finos e médios.
                  </p>
                  <p>
                    Depois do tempo de ação, enxágue e retire o produto dos
                    cabelos sem desenrolar os bigodins. Em seguida utilize o
                    neutralizante Anaconda Cosméticos para desenrolar os
                    bigodins, deixando-o agir por 15 minutos. Para finalizar,
                    enxague até retirar totalmente o produto e deixe o cabelo
                    secar naturalmente.
                  </p>
                </div>
                <div className="col d-flex ">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.espumaNylon}
                    alt="Linha profissional EspumaNylon Permanente Fria e relaxamento"
                  />
                </div>
              </div>
              <div className="info-prod row px-5">
                <div className="col">
                  <h3 className="h4 font-weight-bold pt-5 pb-4">
                    Veja como usar: <br />
                    Para o procedimento do relaxamento:
                  </h3>
                  <p>
                    Lave os cabelos com shampoo e ainda com os fios úmidos,
                    divida-o em 4 partes e aplique o produto, mecha a mecha,
                    massageando os cabelos, respeitando a distância de 1 cm da
                    raiz.
                  </p>
                  <p>
                    Após a aplicação deixe o produto agir até no máximo 25
                    minutos em fios grossos e no máximo 15 minutos em fios finos
                    e médios.
                  </p>
                  Depois do tempo de ação, enxágue e retire o produto sem deixar
                  nenhum resíduo. Em seguida, utilize o neutralizante Anaconda
                  Cosméticos, deixando-o agir por 15 minutos. Para finalizar,
                  enxague até retirar totalmente o produto e deixe o cabelo
                  secar naturalmente.
                </div>
                <div className="col d-flex ">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.espumaNylon}
                    alt="Linha profissional EspumaNylon Permanente Fria e relaxamento"
                  />
                </div>
              </div>

              <div className="info-prod row px-5">
                <div className="col">
                  <h3 className="h4 font-weight-bold pt-5 pb-4">
                    Informações importantes:
                  </h3>
                  <ul>
                    <li>
                      • Os produtos de relaxamento, permanente e alisamento
                      Espuma Nylon são indicados apenas para uso profissional.
                    </li>
                    <li>
                      • As linhas Espuma Nylon e Neutralizante não são testadas
                      em animais.
                    </li>
                    <li>
                      • Os produtos devem ser usados em conjunto com o
                      Neutralizante Anaconda Cosméticos.
                    </li>
                    <li>• Utilize luvas durante a aplicação.</li>
                    <li>• Não repita o processo antes de 30 dias.</li>
                    <li>• Evite contato com olhos e mucosas.</li>
                    <li>
                      • Não utilize bigodim de elástico e nem de alumínio.
                    </li>
                  </ul>
                </div>
                <div className="col d-flex ">
                  <img
                    className=" m-auto border-2"
                    src={imgs.neutralizante}
                    alt="Linha profissional Neutralizante"
                  />
                </div>
              </div>
            </div>
            <button type="button" className="seta-next my-auto" onClick={next}>
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
        </div>
      </Container>
      <BannerCatalogo />
      <LocalCatalogo />
      <Footer />
    </>
  );
}

export default Profissional;
