import styled from 'styled-components';

export const Container = styled.div`
  ul {
    li a,
    .produtos {
      color: #000 !important;
      font-weight: 600;
      font-size: 0.8rem;
    }
    & li a:hover {
      text-decoration: none;
    }
  }
  .submenu {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
    position: absolute;
    background-color: #fff;
    margin-top: 16px;
    z-index: 4;
  }
  .submenu li {
    font-size: 0.8rem;
    padding: 0.5rem 0.8rem;
  }

  .produtos:hover .submenu {
    height: 250px;
  }

  .logo {
    filter: invert();
    margin: auto;
  }
  .span-3 {
    transition: visibility 1s ease;
    opacity: 1;
  }

  .show {
    opacity: 0;
  }
  .mobile-only,
  .mobile-only-social {
    display: none !important;
  }

  .menu-btn {
    border: none;
    width: 30px;
    height: 30px;
    cursor: pointer;
    &:active,
    &:focus {
      outline: none;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 62px;
      background: #333;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
      opacity: 0;
    }
    &__icon {
      position: relative;
      width: 50px;
      height: 40px;
      margin: 0 auto;
      cursor: pointer;
    }
  }
  .icon--menu {
    span {
      display: block;
      width: 30px;
      height: 4px;
      background: #333;
      transition: all 0.18s;
      transition-timing-function: $transition;
      transition-delay: 0.05s;
      &:not(last-child) {
        margin-bottom: 6px;
      }
    }
  }

  .icon--close {
    transform: scale(0);
    transform-origin: center center;
    transition: all 0.18s;
    transition-timing-function: $transition;
    transition-delay: 0.05s;
    position: relative;
    top: -50%;

    span {
      position: relative;
      display: block;
      width: 30px;
      height: 4px;
      background: #333;
      transition: all 0.18s;
      transition-timing-function: $transition;
      transition-delay: 0.05s;
      &:nth-of-type(1) {
        top: 17px;
        transform: rotate(45deg);
      }
      &:nth-of-type(2) {
        top: 13px;
        transform: rotate(-45deg);
      }
    }
  }

  .is--active {
    .icon--menu {
      span {
        width: 0;
      }
    }
    .icon--close {
      transform: scale(1);
      opacity: 1;
    }
  }

  .is--clicked {
    &:after {
      animation: btnClick 0.35s;
      animation-timing-function: cubic-bezier(0.17, 0.67, 0.42, 1.36);
    }
  }

  @keyframes btnClick {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    50% {
      opacity: 0.35;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(0);
    }
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .vh-100 {
    height: 100vh;
  }
  .slide-in-left {
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  @-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  .slide-out-left {
    -webkit-animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
      both;
    animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }
  @-webkit-keyframes slide-out-left {
  }
  @keyframes slide-out-left {
  }
  img {
    max-width: none;
  }
  @media (min-width: 769px) {
    .md-none {
      display: none;
    }
    .navbar-nav {
      flex-direction: row !important;
    }
  }
  @media (max-width: 768px) {
    li {
      margin: 0 !important;
    }

    .nav-link {
      text-align: left !important;
    }
    .logo {
      margin: auto !important;
    }
    .m-auto {
      margin: 0 !important;
    }
    .sm-none {
      display: none;
    }
    .navbar {
      width: fit-content !important;
      position: absolute;
      z-index: 3;
      background-color: #fff;
    }
    .mobile-only {
      display: block !important;
    }
    .mobile-only-social {
      display: flex !important;
    }
    .desk-only {
      display: none;
    }
    nav {
      padding-left: 8vw;
    }
    ul {
      li a,
      .produtos {
        font-size: 1.6em;
      }
      .produtos:hover .submenu {
        position: relative;
        height: 320px;
      }

      .slide-out-left {
        -webkit-animation: slide-out-left 0.5s
          cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
        animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
          both;
      }
      @-webkit-keyframes slide-out-left {
        0% {
          -webkit-transform: translateX(0);
          transform: translateX(0);
          opacity: 1;
        }
        100% {
          -webkit-transform: translateX(-1000px);
          transform: translateX(-1000px);
          opacity: 0;
        }
      }
      @keyframes slide-out-left {
        0% {
          -webkit-transform: translateX(0);
          transform: translateX(0);
          opacity: 1;
        }
        100% {
          -webkit-transform: translateX(-1000px);
          transform: translateX(-1000px);
          opacity: 0;
        }
      }
    }
    @media (max-width: 524px) {
      .w-sm-100 {
        width: 100%;
      }
    }
  }
`;
