import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from './styles';
import Footer from '~/components/Footer';
import LocalCatalogo from '~/components/LocalCatalogo';
import BannerCatalogo from '~/components/BannerCatalogo';
import { imgs } from '~/assets/retokLeg';
import setaPrev from '~/assets/icons/seta-prev.gif';
import setaNext from '~/assets/icons/seta-next.gif';
import BotaoComprar from '~/components/BotaoComprar';
function Biondina() {
  let pos = 0;

  function next() {
    if (pos + 1 < Array.from(document.querySelectorAll('.info-prod')).length) {
      const width = document.querySelector('.infos').offsetWidth;
      document.querySelector('.infos').scrollLeft += width;
      pos += 1;
      document.querySelector('.seta-prev').style.visibility = 'visible';
      if (
        pos ===
        Array.from(document.querySelectorAll('.info-prod')).length - 1
      ) {
        document.querySelector('.seta-next').style.visibility = 'hidden';
      } else {
        document.querySelector('.seta-prev').style.visibility = 'visible';
      }
    }
  }

  function prev() {
    if (pos - 1 >= 0) {
      const width = document.querySelector('.infos').offsetWidth;
      document.querySelector('.infos').scrollLeft -= width;
      pos -= 1;
      document.querySelector('.seta-next').style.visibility = 'visible';
      if (pos === 0) {
        document.querySelector('.seta-prev').style.visibility = 'hidden';
      } else {
        document.querySelector('.seta-prev').style.visibility = 'visible';
      }
    }
  }
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Anaconda Cosméticos – Retok Legs e Corporal</title>
          <meta
            name="description"
            content="Conheça a linha de produtos Retok Legs e corporal da Anaconda Cosméticos. Maquiagem para pernas e iluminador corporal."
          />
        </Helmet>
        <div className="row-100 fundo-destaque">
          <div className="container h-100 d-flex">
            <div className="ml-5 mobile-ml-1 my-auto">
              <h2 className="h1 text-uppercase retoklegs">
                Retok Legs e Corporal
              </h2>
              <p className="desc-produto ">
                Conheça a linha de produtos Retok Legs e corporal da Anaconda
                Cosméticos. Maquiagem para pernas e iluminador corporal
              </p>
            </div>
          </div>
        </div>
        <div className="row-100 ">
          <div className="container border bg-white mt-n5 px-4 py-4">
            <h1 className="h2 font-weight-bold text-center py-5 m-auto frase-principal">
              Hidratação, suavidade e luminosidade para a sua&nbsp;pele
            </h1>
            <BotaoComprar/>
          </div>
        </div>
        <div className="row-100 fundo-tarja">
          <div className="container d-flex h-100">
            <h2 className="h6 w-50 text-uppercase text-center m-auto">
              Cosméticos desenvolvidos para você brilhar muito
            </h2>
          </div>
        </div>

        <div className="row-100 bg-white border-left border-right border-bottom py-5 mobile-row">
          <div className="container d-flex">
            <button type="button" className="seta-prev my-auto " onClick={prev}>
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>

            <div className="row align-items-center infos">
              <div className="info-prod row px-5">
                <div className="col">
                  <h3 className="h4 font-weight-bold pt-5 pb-4">Retok Legs</h3>
                  <h3 className="h5 font-weight-bold">
                    Pernas lisas e perfeitas com efeito meia-calça:
                  </h3>
                  <p>
                    O Retok Legs é um creme ou spray que foi desenvolvido para
                    disfarçar pequenas imperfeições nas pernas como:
                    microvarizes, varizes, vasos e cicatrizes que podem causar
                    constrangimento em algumas mulheres. O produto apresenta
                    fácil aplicação e oferece efeito de meia-calça.
                  </p>
                  <p>
                    O produto possui alta fixação e está disponível em três tons
                    diferentes e sua fórmula contém hidratante, que além de
                    cobrir as imperfeições ajuda a deixar as pernas mais macias.
                    Para utilizar, aplique uniformemente em toda a extensão das
                    pernas.
                  </p>
                  <h3 className="h5 font-weight-bold">
                    Orientações para um resultado incrível
                  </h3>
                  <ul>
                    <li>
                      • Uso não recomendado para imersão prolongada na água.
                    </li>
                    <li>
                      • O produto sai facilmente nos banhos diários
                      convencionais.
                    </li>
                    <li>
                      • Recomendado o uso de proteção solar, pois o Retok Legs
                      não possui fator de proteção.
                    </li>
                    <li>• Não mancha a pele e é totalmente seguro.</li>
                    <li>
                      • Pode ser usado em todos os tipos de pele e em todas as
                      idades.
                    </li>
                  </ul>
                </div>
                <div className="col d-flex col-img-produto">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.meiaCalca}
                    alt="Retok Legs Base e Maquiagem para as pernas"
                  />
                </div>
              </div>
              <div className="info-prod row px-5 ">
                <div className="col">
                  <h3 className="h4 font-weight-bold pt-5 pb-4">
                    Spray glitter:
                  </h3>
                  <h3 className="h5 font-weight-bold">
                    Glamour e brilho na hora da diversão:
                  </h3>
                  <p>
                    A Anaconda Cosméticos desenvolveu um produto divertido e
                    descolado para você brilhar onde for. A linha Spray Glitter
                    está disponível em três cores diferentes: dourado, prata e
                    verde. O produto pode ser aplicado tanto no cabelo como no
                    corpo.
                  </p>
                  <h3 className="h5 font-weight-bold">
                    Orientações para um resultado incrível
                  </h3>
                  <ul>
                    <li>• O produto não deve ser aplicado no rosto.</li>
                    <li>
                      • Conteúdo inflamável. Não use ou guarde perto do calor,
                      faísca ou chama.
                    </li>
                    <li>
                      • Evite contato com os olhos, se houver contato, lave
                      imediatamente.
                    </li>
                    <li> • Apenas para uso externo.</li>
                    <li>• Mantenha fora do alcance de crianças.</li>
                  </ul>
                </div>
                <div className="col d-flex col-img-produto">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.glitter}
                    alt="Retok Legs Spray glitter"
                  />
                </div>
              </div>
              <div className="info-prod row px-5">
                <div className="col">
                  <h3 className="h4 font-weight-bold pt-5 pb-4"> Body Glow:</h3>
                  <h3 className="h5 font-weight-bold">
                    Pele iluminada e vibrante
                  </h3>
                  <p>
                    O Body Glow é um iluminador corporal que proporciona um
                    bronze instantâneo e hidrata a pele com brilhos. Deixando um
                    aspecto de pele luminosa e perfumada com a essência de
                    frutas vermelhas.
                  </p>
                  <h3 className="h5 font-weight-bold">
                    Orientações para um resultado incrível
                  </h3>
                  <ul>
                    <li>
                      • Espalhe o produto uniformemente, evitando acúmulos.
                    </li>
                    <li>• O produto pode ser usado de dia e à noite.</li>
                    <li>
                      • Utilize o produto para destacar pernas, braços, colo e
                      clavículas.
                    </li>
                  </ul>
                </div>
                <div className="col d-flex col-img-produto">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.glow}
                    alt="Retok Legs Body Glow"
                  />
                </div>
              </div>
            </div>
            <button type="button" className="seta-next my-auto" onClick={next}>
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
        </div>
      </Container>
      <BannerCatalogo />
      <LocalCatalogo />
      <Footer />
    </>
  );
}

export default Biondina;
