const img = require('./Biondina.png');
const img2 = require('./Refil.png');
const img3 = require('./beach.png');
const img4 = require('./BiondinaRefil.png');
const img5 = require('./familia.jpg');
const img6 = require('./mascaraHidratacao.jpeg');

export const imgs = {
  biondina: img,
  refil: img2,
  beach: img3,
  biondinaRefil: img4,
  familia: img5,
  mascaraHidratacao: img6,
};
