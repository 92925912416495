import styled from 'styled-components';
import img from '~/assets/img-quem-somos.jpg';

export const Container = styled.div`
  color: #292929;
  h1 {
    font-size: 3.5rem;
  }
  .eficacia-comprovada,
  .texto-quem-somos {
    font-size: 1rem;
    max-width: 80%;
  }
  .subtitulo {
    max-width: 60%;
    margin: auto;
  }
  .fundo-texto {
    background-color: #e9424e;
    color: #fff;
  }

  .img-quem-somos {
    background-image: url(${img});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  @media (max-width: 1024px) {
    .eficacia-comprovada,
    .texto-quem-somos {
      max-width: 95%;
    }
    .eficacia-comprovada,
    .texto-quem-somos {
      font-size: 1rem;
    }
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 2.2rem !important;
    }
    .img-quem-somos {
      height: 376px;
    }
    .img-texto {
      display: block !important;
    }
    .fundo-texto {
      width: 100% !important;
      padding: 2rem !important;
    }

    .subtitulo {
      max-width: 100%;
      margin: auto;
    }
    .eficacia-comprovada,
    .texto-quem-somos {
      font-size: 1rem;
      max-width: 100%;
    }
    .mobile {
      width: 100% !important;
    }
  }
`;
