import styled from 'styled-components';
import { produtos } from '~/assets/produtos_home';
import { linhas } from '~/assets/linhas_home';
import { fotosInsta } from '~/assets/insta';
import Banner from '~/assets/banner.png';

export const Container = styled.div`
  background-color: #f5f5f5;

  h3 {
    font-size: 0.8rem;
  }
  h2 {
    font-size: 1.4rem;
  }
  h5 {
    font-size: 1.1rem;
  }
  .font-size-4 {
    font-size: 4rem;
    font-weight: 300;
  }
  .produto-topo-desc {
    font-weight: 200;
  }
  .btn-topo {
    border-radius: 0;
    border: 2px solid #fff;
    font-weight: bold;
    padding: 0.8rem 1.8rem;
    color: #fff;
    transition: all 1s ease;
  }
  .btn-topo:hover {
    border-radius: 40px;
    background: #000;
    transition: all 1s ease;
    border: 2px solid #000;
  }
  .banner-topo {
    background-image: url(${Banner});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #fff;
    height: 500px;
  }
  .insta,
  .produto {
    width: 288px;
    height: 288px;
    max-width: 288px;
    min-width: 288px;
    background-color: #fff;
    box-shadow: 0px 6px 12px #00000029;
  }
  .insta:hover > .btn-insta {
    border: 2px solid #000;
    opacity: 1;
    padding: 0.5rem 1rem;
  }
  .linha {
    width: 288px;
    height: 160px;
    max-width: 288px;
    min-width: 288px;
    background-color: #fff;
    box-shadow: 0px 6px 12px #00000029;
  }
  .btn-insta {
    border-radius: 0;
    border: none;
    font-weight: bold;
    padding: 0.8rem 1.2rem;
    background: #fff;
    transition: all 0.5s ease;
    opacity: 0;
  }

  .imgs-insta::-webkit-scrollbar,
  .imgs-linhas::-webkit-scrollbar {
    display: none;
  }
  .imgs-insta,
  .imgs-linhas {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    scroll-behavior: smooth;
  }
  .lm {
    margin-left: -15px;
  }
  .seta-prev,
  .seta-next {
    z-index: 2;
    color: #000;
    border-radius: 100%;
    height: 30px;
    min-width: 30px;
    border: 1px solid #000;
    opacity: 1;
    max-width: 30px;
  }
  .seta-prev {
    margin: auto -30px auto 0;
  }
  .seta-next {
    margin: auto 0 auto -30px;
  }
  .seta-prev img,
  .seta-next img {
    padding: 6px;
    filter: invert();
  }
  .produtos {
    background: #fff;
    box-shadow: 0px 6px 12px #00000029;
  }

  .produto {
    box-shadow: none;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #fff;
  }
  #produto1 {
    background-image: url(${produtos[0]});
  }
  #produto2 {
    background-image: url(${produtos[1]});
  }
  #produto3 {
    background-image: url(${produtos[2]});
  }

  #produto4 {
    background-image: url(${produtos[3]});
  }

  #produto5 {
    background-image: url(${produtos[4]});
  }
  #produto6 {
    background-image: url(${produtos[5]});
  }

  #insta1 {
    background-image: url(${fotosInsta[2]});
  }

  #insta2 {
    background-image: url(${fotosInsta[3]});
  }

  #insta3 {
    background-image: url(${fotosInsta[0]});
  }

  #insta4 {
    background-image: url(${fotosInsta[1]});
  }

  #linha1 {
    background-image: url(${linhas[1]});
  }

  #linha2 {
    background-image: url(${linhas[0]});
  }

  #linha3 {
    background-image: url(${linhas[2]});
  }

  #linha4 {
    background-image: url(${linhas[3]});
  }

  #linha5 {
    background-image: url(${linhas[4]});
  }
  #linha6 {
    background-image: url(${linhas[5]});
  }

  .produto:hover > .btn-insta {
    border: 2px solid #000;
    opacity: 1;
    padding: 0.5rem 1rem;
  }

  .subdesc {
    font-weight: 600;
    font-size: 0.7rem;
    color: #5b5b5b;
  }

  .produto-desc {
    font-size: 0.6rem;
    font-weight: 600;
  }
  .btn {
    border-radius: 0;
    border: 2px solid #000;
    font-weight: bold;
    padding: 0.5rem 1.2rem;
  }
  .nome-linha {
    color: #fff;
    font-size: 1.7rem;
  }
  a,
  a:hover {
    text-decoration: none;
  }
  @media (max-width: 992px) {
    .produto {
      height: 207.328px;
    }
  }
  @media (max-width: 768px) {
    .mobile-block {
      display: block;
    }
    .produto {
      height: 389px;
      max-width: 389px;
      margin-bottom: 2rem !important;
    }
    .produtos {
      max-width: 389px !important;
      width: 100% !important;
      margin: 0 auto !important;
      padding: 0 0 0.5rem 0 !important;
    }
    .desk-only {
      display: none;
    }
  }
  @media (max-width: 400px) {
    h2 {
      font-size: 1.1rem;
    }
  }
`;
