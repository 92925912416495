import styled from 'styled-components';
import BannerContato from '~/assets/BANNER_DESK/tarjas/tarja-contatos.jpg';
import BannerContatoMobile from '~/assets/BANNER-CONTATOS-MOBILE.jpg';

export const Container = styled.div`
  .banner-contatos {
    background-image: url(${BannerContato});
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
    height: 300px;
    display: flex;
    align-items: center;
  }
  h1 {
    font-size: 4rem;
  }
  input,
  textarea {
    border-radius: 1px;
    border: 1px solid #707070;
    font-size: 0.8em;
    font-weight: 600;
    color: #000;
    padding: 1.6em 1em;
  }

  textarea {
    resize: none;
    padding: 1.2em 1em;
  }
  .btn {
    border-radius: 0;
    border: 2px solid #000;
    font-weight: bold;
    padding: 0.5rem 1.2rem;
    min-width: 110px;
    min-height: 44px;
  }
  .loader {
    border: 2.5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2.5px solid #b7b7b7;
    width: 25px;
    height: 25px;
    animation: spin 1s linear infinite;
    margin: auto;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @media (max-width: 768px) {
    .banner-contatos {
      background-image: url(${BannerContatoMobile});
      background-repeat: no-repeat;
      background-position: center;
      color: #fff;
      height: 400px;
      display: flex;
      align-items: center;
    }
    .desk-only {
      display: none !important;
    }
    .mobile-form {
      display: block !important;
      flex: none;
      max-width: 100%;
    }
    h1 {
      font-size: 2.5rem;
      margin: 0 !important;
    }
  }
`;
