import styled from 'styled-components';
import FundoBanner from '~/assets/BANNER_DESK/destaque/destaque-linhas-corporal.jpg';
import Tarja from '~/assets/BANNER_DESK/tarjas/tarja-linhas-corporal.jpg';

export const Container = styled.div`
  .retoklegs {
    max-width: 670px;
    font-size: 3.8rem;
    font-weight: 200;
    text-align: left;
  }
  .frase-principal {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.2rem;
    font-weight: 200;
    max-width: 485.734px;
  }
  .desc-produto {
    font-weight: 300;
    width: fit-content;
    max-width: 320.39px;
  }
  .col-img-produto {
    max-width: fit-content;
  }
  .seta-prev,
  .seta-next {
    z-index: 2;
    color: #292929;
    border-radius: 100%;
    height: 30px;
    min-width: 30px;
    border: 1px solid #292929;
    opacity: 0.4;
  }
  .seta-prev img,
  .seta-next img {
    padding: 6px;
    filter: invert();
  }

  .seta-prev {
    visibility: hidden;
  }

  .fundo-destaque {
    background-image: url(${FundoBanner});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 60%;
    color: #292929;
    height: 460px;
  }
  .fundo-tarja {
    background-image: url(${Tarja});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #292929;
    height: 169px;
  }
  .infos {
    flex-wrap: nowrap;
    overflow-x: hidden;
    margin: 0;
  }
  .info-prod {
    min-width: 100%;
    margin: 0;
  }

  .infos::-webkit-scrollbar,
  .infos::-webkit-scrollbar {
    display: none;
  }
  .infos,
  .infos {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    scroll-behavior: smooth;
  }

  @media (max-width: 768px) {
    .retoklegs {
      font-size: 2.5rem;
      font-weight: 200;
      text-align: left;
    }
    .mobile-ml-1 {
      margin: auto 1rem !important;
    }
    .frase-principal {
      font-size: 1.4rem;
      padding: 1rem 0 !important;
    }

    h3 {
      font-size: 1rem;
      font-weight: 200;
      max-width: 309px;

      text-align: left !important;
      margin: auto 0 !important;
    }
    .fundo-destaque {
      background-size: cover;
      background-position: 38%;
      color: #292929;
      height: 259px;
    }
    .desc-produto {
      max-width: 239.39px;
    }
    .fundo-tarja {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 84%;
      color: #292929;
      height: 169px;
    }
    .info-prod {
      display: block !important;
      text-align: center;
      margin: auto;
      padding: 0 1rem !important;
    }
    .info-prod h4 {
      padding-top: 0 !important;
    }
    .mobile-row {
      padding: 1rem 0 1rem 0 !important;
      margin-bottom: 1rem;
    }
    .mt-n5 {
      margin-top: -1rem !important;
    }
  }

  @media (max-width: 460px) {
    h3 {
      max-width: 232.734px;
    }
  }
`;
