import styled from 'styled-components';

export const Container = styled.div`
  .text-black {
    color: #000;
  }
  background-color: #fff;
  ul {
    li a {
      color: #000 !important;
      font-weight: 600;
      font-size: 0.8rem;
    }
    & li a:hover {
      text-decoration: none;
    }
  }

  h6,
  h4,
  .desc-anaconda,
  small {
    color: #5b5b5b;
  }

  p,
  h6,
  small {
    font-weight: 600;
  }

  h4 {
    font-weight: bold;
  }

  input,
  textarea {
    border-radius: 1px;
    border: 1px solid #707070;
    font-size: 0.8em;
    font-weight: 600;
    color: #000;
    padding: 1.6em 1em;
  }

  textarea {
    resize: none;
    padding: 1.2em 1em;
  }
  .logo {
    filter: invert();
  }
  .formas-contato {
    color: #000;
    font-weight: 600;
  }
  .btn {
    border-radius: 0;
    border: 2px solid #000;
    font-weight: bold;
    padding: 0.5rem 1.2rem;
    min-width: 110px;
    min-height: 44px;
  }
  .loader {
    border: 2.5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2.5px solid #b7b7b7;
    width: 25px;
    height: 25px;
    animation: spin 1s linear infinite;
    margin: auto;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .desc-anaconda {
    font-size: 0.9rem;
    line-height: 1rem;
  }
  .coluna-contato {
    max-width: 35%;
  }

  @media (max-width: 768px) {
    .desk-only {
      display: none !important;
    }
    .coluna-contato {
      max-width: 100%;
    }
  }
  @media (max-width: 575px) {
    background-color: #f5f5f5 !important;
  }
`;
