const img = require('./biodina.jpg');
const img2 = require('./clareador-facial.jpg');
const img3 = require('./dermoglam.jpg');
const img4 = require('./linha-profissional.jpg');
const img5 = require('./maquiagem.jpg');
const img6 = require('./retok.jpg');
const img7 = require('./retoklegs.jpg');

export const imgs = {
  biondina: img,
  clareador: img2,
  dermoglam: img3,
  profissional: img4,
  maquiagem: img5,
  retok: img6,
  retoklegs: img7,
};
