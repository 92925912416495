import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f5f5f5;
  ul {
    li a {
      color: #000 !important;
      font-weight: 600;
      font-size: 0.8rem;
    }
    & li a:hover {
      text-decoration: none;
    }
  }

  h6,
  h4,
  .desc-anaconda,
  small {
    color: #5b5b5b;
  }

  p,
  h6,
  small {
    font-weight: 600;
  }

  h4 {
    font-weight: bold;
  }
  .text-black {
    color: #000;
  }
  .logo {
    filter: invert();
  }
  .formas-contato {
    color: #000;
    font-weight: 600;
  }

  .desc-anaconda {
    font-size: 0.9rem;
    line-height: 1rem;
  }
  .coluna-contato {
    max-width: 35%;
  }

  @media (max-width: 768px) {
    .mobile-block {
      display: block !important;
    }
    .mobile-border {
      border: none !important;
      padding: 0 1.5rem !important;
    }
    .formas-contato {
      padding: 0 !important;
    }
    .mobile-mt-0 {
      padding-top: 0 !important;
    }
  }
  @media (max-width: 575px) {
    background-color: #f5f5f5 !important;
  }
`;
