import styled from 'styled-components';
import { icons } from '~/assets/icons';

export const Container = styled.div`
  h1 {
    font-size: 2.3rem;
  }
  .temos-clientes {
    font-size: 1.5rem;
    max-width: 60%;
  }
  .subtitulo {
    max-width: 80%;
    font-size: 0.8rem;
    font-weight: 600;
  }
  .icon-lupa {
    position: absolute;
    margin: 8px;
    width: 30px;
  }
  .campo {
    border-radius: 0;
    border: 2px solid #000;
    padding: 0.5rem 1.2rem;
    color: #c3c3c3;
    font-size: 1rem;
    padding-left: 50px;
    height: 45px;
    background-image: url(https://www.anacondacosmeticos.com.br/static/media/lupa.542deef0.svg);
    background-position: 10px;
    background-repeat: no-repeat;
    background-size: 30px;
  }
  .btn2 {
    border-radius: 0;
    border: 2px solid #000;
    font-weight: bold;
    padding: 0.5rem 1.2rem;
    top: -60px;
    font-size: 0.8rem;
    word-wrap: nowrap;
  }
  .banner-amazon {
    max-width: 70%;
  }

  .loader {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #b7b7b7;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin: auto;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 768px) {
    h4 {
      font-size: 1rem;
    }
    li {
      font-size: 0.8rem;
    }
    li a {
      font-size: 0.8rem;
      word-break: break-all;
    }

    .temos-clientes {
      font-size: 1.5rem;
      max-width: 100%;
    }
    .subtitulo {
      max-width: 100%;
      font-size: 0.8rem;
      font-weight: 600;
    }
    .banner-amazon {
      max-width: 100%;
    }
    .desk-only {
      display: none;
    }
    .lista {
      padding: 0 1rem !important;
    }
    .campo {
      margin: 5rem 0 0 !important;
    }
    .mobile-pr-0 {
      padding-right: 0 !important;
    }
  }
  @media (max-width: 475px) {
    h1 {
      font-size: 2rem;
      width: 200%;
      text-align: center;
    }

    .temos-clientes {
      font-size: 1.2rem;
      max-width: 100%;
    }

    .subtitulo {
      text-align: left;
    }
    .banner-amazon {
      max-width: 100%;
    }
    .desk-only {
      display: none;
    }
    .lista {
      padding: 0 1rem !important;
    }
    .campo {
      margin: 1.5rem 0 0 0 !important;
    }
    .mobile-pt-5 {
      padding-top: 3rem !important;
    }
    .mobile-pr-0 {
      padding-right: 0 !important;
    }
  }
`;
