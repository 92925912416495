import styled from 'styled-components';
import FundoBanner from '~/assets/destaque-linhas.jpg';
import FundoBannerMobile from '~/assets/destaque-produtos-linhas-mobile.jpg';

export const Container = styled.div`
  .fundo-banner {
    background-image: url(${FundoBanner});
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
    height: 420px;
  }
  h1 {
    font-size: 4.5rem;
    font-weight: 200;
    max-width: 546.781px;
  }
  a img {
    border: 1px solid #f5f5f5;
  }
  a img:hover {
    border: 1px solid #6c757d;
  }

  .todos {
    font-size: 1.6rem;
  }
  .saiba-mais {
    font-size: 0.7rem;
    font-weight: 600;
  }
  .mobile-only {
    display: none;
  }

  @media (max-width: 992px) {
    h1 {
      font-size: 4.2rem;
    }
  }

  @media (max-width: 768px) {
    .mobile-column {
      display: block !important;
    }
    h1 {
      font-size: 2.2rem;
      width: fit-content;
      text-align: left;
      margin-right: 0 !important;
      max-width: 205.828px;
    }
    .mobile-only {
      display: block;
    }
    .fundo-banner {
      background-image: url(${FundoBannerMobile});
      height: 400px;
    }
    .fundo-banner .container {
      text-align: right;
    }
  }

  @media (max-width: 330px) {
    .todos {
      font-size: 130%;
    }
  }
`;
