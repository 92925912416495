import React from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { Container } from './styles';
import Menu from '~/components/Menu';
import Cruelty from '~/assets/cruelty-free.png';
import BotaoComprar from '~/components/BotaoComprarTop';
import BotaoComprarTop from '~/components/BotaoComprarTop';
function Header() {
  return (
    <>
      <Container>
        <div className=" p-2 fundo-topo text-light">
          <div className="container justify-content-center d-flex p-0">
            <div className="text-right m-auto pl-0 topo">
              <small>Desde 1948 | Não testamos em animais</small>
              <img src={Cruelty} className="logo" alt="Logo Crueltyfree" />
              <BotaoComprarTop/>
            </div>
            <div className="text-right pr-4 redes-sociais">
              <a href="https://www.facebook.com/Anaconda-Cosm%C3%A9ticos-109160597287290/">
              <FaFacebookF size={17} color="#fff" />
              </a>
              <a href="https://www.instagram.com/anaconda.cosmeticosoficial">
              <FaInstagram className="ml-3" size={17} color="#fff" />
              </a>

             
            </div>
          </div>
        </div>
        <div className="row m-0  w-100">
          <hr className="col mt-1 m-0" />
        </div>
        <Menu />
      </Container>
    </>
  );
}

export default Header;
