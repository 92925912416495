import React, { useEffect } from 'react';
import PoliticaPrivacidadeComponent from '~/components/PoliticaPrivacidade';

export default function PoliticaPrivacidade() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PoliticaPrivacidadeComponent
      nome="Anaconda Cosméticos"
      email="fabrica.anaconda@gmail.com"
      site="https://www.anacondacosmeticos.com.br/"
      cnpj="60.891.371/0001-32"
      endereco="Av. Celso Garcia, 676 - Brás - São Paulo/ SP"
      razaoSocial="CASA ADELINO PRODUTOS ANACONDA LTDA"
      plataforma="Google"
      titleColor="#266971"
      contentTextColor="#266971"
      fontRootSize="50%"
    />
  );
}
