import React from 'react';

function BotaoComprarTop() {
return(
  
    <a href="https://www.amazon.com.br/s?rh=n%3A16194414011%2Cp_4%3AAnaconda&ref=bl_dp_s_web_16194414011"
    className="btn3 position-relative" target='_blank'>
    COMPRAR ONLINE
  </a>
  
);
}

export default BotaoComprarTop;